import React from 'react';
import AbstractReactComponent from "../core/view/AbstractReactComponent";
import {DEFAULT_LAYOUT} from "../core/layout/BILayout";
import ILSSLayout from "./layout/ILSSLayout";
import ILSSHeader from "./layout/ILSSHeader";
import CookieUtil from "./util/CookieUtil";

interface COMM_PROPS{
    req:string,
    reqHistory:{time:Date, p:string}[],
    res:string,
    resHistory:{time:Date, p:any}[],
    isDetailed:boolean
}
const defaultReq = ()=>{
    return '{\n' +
        '  "model": 4,\n' +
        '  "max_tokens": 1000,\n' +
        '  "temperature": 0.5,\n' +
        '  "top_p": 1,\n' +
        '  "frequency_penalty": 0,\n' +
        '  "presence_penalty": 0,\n' +
        '  "messages": [\n' +
        '    {\n' +
        '      "role": "user",\n' +
        '      "content": "{\\"previous dialogues\\":[\\"황진성:안녕!\\",\\"이선아:나도 반가워\\",\\"황진성:지금은 뭐하고 있어?\\",\\"이선아:책 보고 있어\\",\\"황진성:귀찮지만 6시에 학교 가야되.. 하..\\",\\"이선아:학교 가는 건 귀찮지. 그래도 6시에 가야 한다니, 힘내!\\"]}"\n' +
        '    },\n' +
        '    {\n' +
        '      "role": "user",\n' +
        '      "content": "user\'s dialogue:새벽 2시에 나와"\n' +
        '    },\n' +
        '    {\n' +
        '      "role": "user",\n' +
        '      "content": "{\\"must respond in \'JSON\' format\\":[{\\"dialogue\\":\\"assistant\'s answer. language is korean\\"},{\\"event_time\\":\\"provide \'event_time\' only if both the user and the assistant have explicitly agreed on a specific time. If there is no clear agreement on the time or if the proposed time is questioned or not confirmed, do not include \'event_time\'. \'event_time\' should only be included if both parties explicitly confirm a specific time and the assistant\'s response is in agreement with that time. Also, consider the \'dialogue\' content when determining \'event_time\'. (respond in \'yyyy-MM-dd HH:mm\' format)\\"}]}"\n' +
        '    }\n' +
        '  ]\n' +
        '}';
}
export default class GptTest extends AbstractReactComponent<any> {
    state: COMM_PROPS = {
        req: defaultReq(),
        reqHistory: [],
        res: '',
        resHistory: [],
        isDetailed: false
    };

    static getDerivedStateFromProps(nextProps: COMM_PROPS, prevState: COMM_PROPS) {
        let p: COMM_PROPS = {
            req: defaultReq(),
            reqHistory: [],
            res: '',
            resHistory: [],
            isDetailed: false
        };
        AbstractReactComponent.pullups(prevState, nextProps, p, 'req', 'reqHistory', 'res', 'resHistory', 'isDetailed');
        let prop:any = CookieUtil.getLocalStorage('ProP');
        if(prop && prop !== '' && (p.reqHistory.length === 0 && p.resHistory.length === 0)){
            try {
                p = prop;
            }catch (e){
                prop = null;
            }
        }else{
            CookieUtil.setLocalStorage('ProP', p);
        }
        return p;
    }

    handleReqChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            req: event.target.value
        });
    }

    handleDetailChange = () => {
        this.state.isDetailed = !this.state.isDetailed;
        this.setState({
            isDetailed: this.state.isDetailed,
            res:this.getResPacketString(this.state.resHistory[0])
        });
    }

    getResPacketString(res:any):string{
        if(!res) return '';
        if(this.state.isDetailed){
            return JSON.stringify(res, null, 2);
        }
        return JSON.stringify(res.p.ResPacket, null, 2);
    }

    clearBackup():void {
        const that = this;
        try{
            CookieUtil.setLocalStorage('ProP', null);
        }catch(e){
            alert('패킷 형식이 올바르지 않습니다.'+e);
            console.log(e);
        }
    }

    sendPacket():void {
        this.transaction.token = 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImQyZDQ0NGNmOGM1ZTNhZTgzODZkNjZhMTNhMzE2OTc2YWEzNjk5OTEiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIxODQ3MDE3NzIzMzUtOTdnZGFtY3BlNWtsMTFub2xrZW81bDF0azNuM2ttNHMuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJhdWQiOiIxODQ3MDE3NzIzMzUtOGxuYmVkaXBpdjdhYWRvcTF0YWw3Z25kdTRtcmY0YnUuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJzdWIiOiIxMDg0NTAwNjQzNTg5Mzk1NjYxODkiLCJlbWFpbCI6InR3aW50dC5za3lAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImlhdCI6MTcyMzg3NDYwNSwiZXhwIjoxNzIzODc4MjA1fQ.m5pNfBxz_GWDoSqxIcCo0SN4KhMwGgKYpGAA2hTNWusDWvx4Tu0BnyZYPzalel3_Hb94C-4EXHTZcAcSqsAgdzBXOd2HMxqtDJibxcKcS6kPaGWZMagd7MRhWm-XO95dNT9NseftBDW-ijKHB3rJfDa3lklcgknwnTpoFsnjaPl9hpqW1G-qt8nUZWPFGgF8YutJdRH7dwsl9E5-x-DZkLXSblBPGfEXeh4BuU6-eFpEB52gHhbcD_OWbp5feaKn7wKibCH2r3VW4EiRgGTY5lOm3CACbQQmvknbZLLG0dG35b_ymi-rMSgo-y5eaSE6xqXo1Mn4muVqQLSQYHpHYQ';
        // 패킷 전송 로직
        const that = this;
        try{
            const reqObj = JSON.parse(this.state.req);
            this.setState({reqHistory:[{time:new Date(), p:this.state.req}, ...this.state.reqHistory]});
            this.getResponse('openai.prompt.talk', reqObj,{
                success:(res:any, data:any)=>{
                    let his = {time:new Date(), p:res};
                    const resHistory = [his, ...this.state.resHistory]
                    this.setState({res:that.getResPacketString(his), resHistory:resHistory});
                }
            })

        }catch(e){
            alert('패킷 형식이 올바르지 않습니다.'+e);
            console.log(e);
        }
    }

    historyClick(type:string, data:any):void {
        if(type === 'req'){
            this.setState({req:data.p});
        }else{
            this.setState({res:this.getResPacketString(data)});
        }
    }

    getReqHistory():JSX.Element[]{
        let jsx:JSX.Element[] = [];
        this.state.reqHistory.map((req, idx)=>{
            jsx.push(
                <div key={idx} className={'history-item'}>
                    <div className={'history-header'}>
                        <span><a onClick={()=>this.historyClick('req', req)}>{req.time.toString()}</a></span>
                    </div>
                </div>
            );
        });
        jsx.push(<button onClick={()=>this.clearBackup()}>이력삭제</button>);
        return jsx;
    }

    getResHistoryNumber(p:any):JSX.Element{
        let jsx:JSX.Element = <></>;
        if(p && p.ResPacket){
            jsx = <span>{p.ResPacket.model} / prompt:{p.ResPacket.usage.prompt_tokens}, completion:{p.ResPacket.usage.completion_tokens}, total:{p.ResPacket.usage.total_tokens}</span>;
        }
        return jsx;
    }

    getResHistory():JSX.Element[]{
        let jsx:JSX.Element[] = [];
        this.state.resHistory.forEach((res, idx)=>{
            jsx.push(
                <div key={idx} className={'history-item'}>
                    <div className={'history-header'}>
                        <div className={'res-time'}><a onClick={()=>this.historyClick('res', res)}>{res.time.toString()}</a></div>
                        <div className={'res-token'}>{this.getResHistoryNumber(res.p)}</div>
                    </div>
                </div>
            );
        });
        return jsx;
    }

    render(): JSX.Element {
        let btnState = {
            text: '응답전체', cls:'btn-yellow'
        }
        if(!this.state.isDetailed) {
            btnState.text = 'AI응답';
            btnState.cls = 'btn-red';
        }

        return (
            <>
                <ILSSLayout context={this} type={DEFAULT_LAYOUT.MAIN}>
                    <div className={'row full mt-20'}>
                        <div className={'col-6 req'}>
                            <div className={'col-header'}>
                                <h2>요청패킷</h2>
                                <div className={'btnset'}>
                                    <button className={'btn btn-sm btn-primary'} onClick={()=>this.sendPacket()}>전송</button>
                                </div>
                            </div>
                            <div className={'col-body'}>
                                <textarea className={'packet'} value={this.state.req} onChange={this.handleReqChange}></textarea>
                            </div>
                            <div className={'history'}>
                                {this.getReqHistory()}
                            </div>
                        </div>
                        <div className={'col-6 res'}>
                            <div className={'col-header'}>
                                <h2>응답패킷</h2>
                                <div className={'btnset'}>
                                    <button className={'btn btn-sm '+btnState.cls}
                                            onClick={(e)=>this.handleDetailChange()}>{btnState.text}</button>
                                </div>
                            </div>
                            <div className={'col-body'}>
                                <textarea className={'packet'} value={this.state.res}></textarea>
                            </div>
                            <div className={'history'}>
                                {this.getResHistory()}
                            </div>
                        </div>
                    </div>
                </ILSSLayout>
            </>
        );
    }
}