import {MutableRefObject} from "react";
import CHeader from "../layout/CHeader";
import AbstractReactComponent from "../view/AbstractReactComponent";
import Bottom from "../layout/Bottom";
import Footer from "../layout/Footer";
import Left from "../layout/Left";

export interface REF_OBJECTS{
    [key:string]:MutableRefObject<any>
}


export enum EVENT{
    TRANS_REQ,
    TRANS_RES,
    RELOAD,
    ON,
    POPUP_CALLBACK,
    ERROR,
}

export enum HUNT_STATE{
    INTRO, SCENARIO, WAIT, WAR_PLAY, RESULT, NEXT
}

export interface LAYOUT_CONFIG{
    HEADER:typeof AbstractReactComponent<any>,
    CHEADER:typeof AbstractReactComponent<any>,
    LEFT:typeof AbstractReactComponent<any>,
    CONTENT?:typeof AbstractReactComponent<any>,
    BOTTOM:typeof AbstractReactComponent<any>,
    FOOTER:typeof AbstractReactComponent<any>,
}

export let LAYOUT_ASSEMBLE:LAYOUT_CONFIG = {
    HEADER: CHeader,
    CHEADER: CHeader,
    LEFT: Left,
    BOTTOM: Bottom,
    FOOTER: Footer,
}