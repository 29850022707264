import React from 'react';
import AbstractReactComponent from "../../core/view/AbstractReactComponent";
import {EVENT} from "../../core/define/types";
import {GoogleCredential} from "../auth/GoogleCertifier";

interface COMM_PROPS{
    credentials?: GoogleCredential
}
export default class ILSSHeader extends AbstractReactComponent<any> {
    state: COMM_PROPS = {};

    static getDerivedStateFromProps(nextProps: COMM_PROPS, prevState: COMM_PROPS) {
        let p: COMM_PROPS = {};
        AbstractReactComponent.pullups(prevState, nextProps, p, "credentials");
        return p;
    }

    on(event: EVENT, data: any, spread?: boolean) {
        super.on(event, data, spread);
        if(event === EVENT.ON){
            if(data.type === 'credentials'){
                this.setState({credentials:data.data});
            }
        }
    }
    getUserInfoJSX():JSX.Element{
        let jsx:JSX.Element = <></>;
        if(this.state.credentials){
            let img = <></>;
            if (this.state.credentials.user.photoURL) {
                img = <img src={this.state.credentials.user.photoURL}/>
            }
            jsx = <div className={'user-info'}>
                {img}
                <a>{this.state.credentials.user.displayName} ({this.state.credentials.user.email})</a>
            </div>;
        }
        return jsx;
    }
    render(): JSX.Element {
        let layout: JSX.Element = <></>;
        layout = (<>
            <h1 className="app-header">
                <a href={"/"} >NERDFOX GAMES</a>
                {this.getUserInfoJSX()}
            </h1>
        </>);
        return layout;
    }
}
