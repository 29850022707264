import {MutableRefObject} from "react";
import {DOM_DATA, DomAnimator} from "./DomAnimator";

interface POPUP_STATE{
    [key:string]:number
}
export class PopupAnimator extends DomAnimator{
    wrap?:MutableRefObject<any>;
    popupOrder(type:string): DOM_DATA|undefined {
        let currPopup:DOM_DATA|undefined= undefined;
        if (this.doms) {
            this.zIndexStack++;
            this.doms.forEach((d, idx) => {
                if(d.idx == type) currPopup = d;

                if (!d.isShow) d.dom.current.style.zIndex = idx;
                else if(d.idx == type) d.dom.current.style.zIndex = this.zIndexStack;   //현재 팝업은 가장 위로
                else d.dom.current.style.zIndex = d.zIndex;   //보이는 팝업은 숨김팝업들보다 위, 마지막 최고점
            });
        }
        return currPopup;
    }

    public select(key:string):DOM_DATA|undefined{
        if(!this.doms) return;
        const curr:DOM_DATA|undefined = this.popupOrder(key);
        if (this.doms) {
            this.doms.forEach((d, idx) => {
                this.hide(d.idx);
            });
        }
        if(curr) this.show(curr.idx);
        return curr;
    }

    public show(key:string){
        const curr:DOM_DATA|undefined = this.popupOrder(key);
        if(curr && !curr?.isShow) {
            curr.dom.current.style.transform = `translateY(-200%)`;
            curr.dom.current.style.opacity = `1`;
            curr.isShow = true;
        }
    }
    public hide(key:string){
        const curr:DOM_DATA|undefined = this.popupOrder(key);
        if(curr && curr?.isShow) {
            curr.dom.current.style.transform = `translateY(0%)`;
            curr.dom.current.style.opacity = `0`;
            curr.isShow = false;
        }
    }

    public hideAll(){
        if (this.doms) {
            this.doms.forEach((d, idx) => {
                this.hide(d.idx);
            });
        }
    }
    public hideWrap(wrap?:MutableRefObject<any>){
        if(wrap) wrap.current.style.transform = `translateY(0%)`;
    }
}