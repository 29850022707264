
export const DEFAULT_CONFIG = {
    // domain:''
    domain:'https://auth.nerdfox.co.kr'
}






