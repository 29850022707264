import Transaction from "../../core/transaction/Transaction";
import {EVENT} from "../../core/define/types";

export default class NerdFoxCertifier{
    AuthorizationToken?:string;
    transaction:Transaction = Transaction.with();

    constructor(token?:string) {
        this.setToken(token);
    }
    setToken(token?:string) {
        this.AuthorizationToken = token;
    }
    signJwtIn(callback:Function) {
        this.transaction.setToken(this.AuthorizationToken);
        this.transaction.url('/google/api/service/oauth/oauth2callback?code='+this.AuthorizationToken, null, {
            success:(res:any, data:any)=>{
                if(callback) callback(EVENT.TRANS_RES, {
                    res:res, data:data
                });
            }
        })
    }
}
export const nerdfoxCertifier = new NerdFoxCertifier();