import React from 'react';
import AbstractReactComponent from "../core/view/AbstractReactComponent";
import {DEFAULT_LAYOUT} from "../core/layout/BILayout";
import {BOARD_PAGE} from "./define/types";
import ILSSLayout from "./layout/ILSSLayout";

interface COMM_PROPS{
    search:BOARD_PAGE,
}
export default class AuthSuccess extends AbstractReactComponent<any> {
    state:COMM_PROPS = {search:{page:0}};

    static getDerivedStateFromProps(nextProps: COMM_PROPS, prevState: COMM_PROPS) {
        let p: COMM_PROPS = {search:{page:0}};
        AbstractReactComponent.pullups(prevState, nextProps, p, "search");
        return p;
    }

    onClick(){
        self.close()
    }

    render(): JSX.Element {
        return (
            <>
                <ILSSLayout context={this} type={DEFAULT_LAYOUT.MAIN} >
                    <div className={'google-auth'}>
                        인증성공
                    </div>
                    <div className={'btn-set dual'}>
                        <button className={'btn btn-purple btn-ico2 scaled-el'} onClick={()=>this.onClick() }>닫기</button>
                    </div>
                </ILSSLayout>
            </>
        );
    }
}
