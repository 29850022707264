import React from 'react';
import AbstractReactComponent from "../core/view/AbstractReactComponent";
import {DEFAULT_LAYOUT} from "../core/layout/BILayout";
import {EVENT, REF_OBJECTS} from "../core/define/types";
import {uxStatus} from "./layout/UXStatus";
import {BOARD_CONTENT, BOARD_PAGE} from "./define/types";
import ILSSLayout from "./layout/ILSSLayout";
import {frontUtil} from "../core/util/FrontUtil";
import AboutUs from "./component/AboutUs";
import BoardWrite from "./component/BoardWrite";
import QuestWrite from "./component/QuestWrite";
import {googleCertifier, GoogleCredential} from "./auth/GoogleCertifier";
import UserInfo from "./component/UserInfo";

interface COMM_PROPS{
    search:BOARD_PAGE,
    isNext:boolean,
    board_content?:BOARD_CONTENT[]
    credentials?: GoogleCredential
}
export default class ILSSBoard extends AbstractReactComponent<any> {
    popups:REF_OBJECTS = {};
    popupKeys:string[] = ['aboutus', 'boardwrite', 'questwrite', 'userinfo'];
    state:COMM_PROPS = {search:{page:0}, isNext:true};

    constructor(props?: any) {
        super(props);
        this.popups = this.toRef(this.popupKeys);
        uxStatus.addPopupElement(this.popups);
    }

    static getDerivedStateFromProps(nextProps: COMM_PROPS, prevState: COMM_PROPS) {
        let p: COMM_PROPS = {search:{page:0}, isNext:true};
        AbstractReactComponent.pullups(prevState, nextProps, p, "search", "board_content", "isNext");
        return p;
    }

    on(event: EVENT, data: any, spread?: boolean) {
        super.on(event, data, spread);
        if(event === EVENT.ON){
            if(data.type === 'close'){
                uxStatus.showPopup(data.sender, false);
                this.setState({search:{page:0}});
                if(data && data.refresh === true){
                    this.init();
                }
                this.init();
            }
        }
    }

    init():void{
        //로딩 시작
        this.getResponse('/preboard.boardlist', this.state.search, {
            success:(res:any, data:any)=>{
                const result = res.result;
                let board_content = this.state.board_content;
                if(board_content === undefined) board_content = [];
                if(result && result.length > 0){
                    if(data.body.request.page === 0) {
                        board_content = result;
                    }else{
                        board_content = board_content.concat(result);
                    }
                    let search = this.state.search;
                    search.page = data.body.request.page;
                    this.setState({board_content:board_content, search:search});
                }else{
                    let search = this.state.search;
                    search.page = data.body.request.page-1;
                    this.setState({search:search, isNext:false});
                }
            }
        });
    }
    next():void{
        let search = this.state.search;
        search.page++;
        this.setState({search:search});
        this.init();
    }

    componentDidMount(){
        super.componentDidMount();
        uxStatus.addPopupElement(this.popups);
        this.init();
    }

    getBoardListJSX(){
        let jsx:JSX.Element[] = [];
        if(this.state.board_content){
            this.state.board_content.forEach((item:BOARD_CONTENT, index:number)=>{
                jsx.push(<div key={index} className={'board-row list_row_intro1'} style={{animationDelay:`${index*0.001}s`}}>
                    <span className={'email'}>{frontUtil.emailMask(item.email)}</span>
                    <span className={'time'}>{frontUtil.dateTimeAgo(item.reg_dtm)}</span>
                    <div className={'content'} >
                        {item.cont.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </div>
                </div>);
            });
        }
        if(this.state.isNext){
            jsx.push(<div key={'more'} className={'list_row_intro1 more'} style={{animationDelay:`${jsx.length*0.001}s`}}>
                <button className={'btn scaled-el'} onClick={()=>this.next()}>더보기</button>
            </div>);
        }
        return jsx;
    }

    onClick(type:string){
        uxStatus.showPopup(type, true);
        this.getRootContext().on(EVENT.ON, {sender:'context', type:"open", id:type});
    }

    render(): JSX.Element {
        return (
            <>
                <ILSSLayout context={this} type={DEFAULT_LAYOUT.MAIN} >
                    <div className={'content-board'}>
                        {this.getBoardListJSX()}
                    </div>
                    <div className={'btn-set dual'}>
                        <button className={'btn btn-purple btn-ico2 scaled-el'} onClick={()=>this.onClick('aboutus') }>ABOUT US</button>
                        <button className={'btn btn-yellow2 btn-ico1 scaled-el'} onClick={()=>this.onClick('boardwrite') }>야! 그런데.</button>
                    </div>
                </ILSSLayout>
                <div className="popup-group">
                    <div className={'popup'} ref={this.popups.aboutus}><ILSSLayout type={DEFAULT_LAYOUT.POPUP}><AboutUs context={this} /></ILSSLayout></div>
                    <div className={'popup'} ref={this.popups.boardwrite}><ILSSLayout type={DEFAULT_LAYOUT.POPUP}><BoardWrite context={this} /></ILSSLayout></div>
                    <div className={'popup'} ref={this.popups.questwrite}><ILSSLayout type={DEFAULT_LAYOUT.POPUP}><QuestWrite context={this} /></ILSSLayout></div>
                    <div className={'popup'} ref={this.popups.userinfo}><ILSSLayout type={DEFAULT_LAYOUT.POPUP}><UserInfo context={this} credentials={this.state.credentials} /></ILSSLayout></div>
                </div>
            </>
        );
    }
}
