import dayjs from 'dayjs';
import {v4} from "uuid";
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ko';
import FrontUtil from "./FrontUtil"; // 한국어 로케일을 사용하기 위해

dayjs.extend(relativeTime); // relativeTime 플러그인 활성화
dayjs.locale('ko'); // 한국어 로케일 설정

export default class Validate{
    public email(val:string, isThrow?:boolean):boolean {
        let ret:boolean = false;
        try {
            if(!isThrow) isThrow = true;
            let regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,}$/i;
            ret = regExp.test(val);
            if (isThrow && !ret) {
                throw new Error("올바른 이메일 형식이 아닙니다.");
            }
        }catch(e:any){
            console.log(e);
            alert(e.message);
        }
        return ret;
    }
}

export const validator:Validate = new Validate();