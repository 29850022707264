import {MutableRefObject} from "react";

export interface DOM_DATA{
    idx:string,
    isShow:boolean,
    index:number,
    zIndex:number,
    dom:MutableRefObject<any>,
}
export abstract class DomAnimator {
    doms:DOM_DATA[] = [];
    baseIndex:number = 0;
    index:number = 0;
    indexStack:number = 0;
    zIndexStack:number = 0;

    init(baseIndex:number){
        this.baseIndex = baseIndex;
        this.index = 0;
        this.indexStack = this.baseIndex;
        this.zIndexStack = this.baseIndex;
    }
    public clear(){
        this.doms = [];
        this.index = 0;
        this.zIndexStack = this.baseIndex;
    }
    public set(dom:MutableRefObject<any>[]):DOM_DATA[]{
        if (dom) {
            this.doms = [];
            this.indexStack = this.baseIndex;
            dom.forEach((d, idx)=>{
                this.index = idx;
                this.doms.push({idx:String(idx), index:this.index, isShow:false, zIndex:this.indexStack, dom:d});
                this.indexStack++;
                this.zIndexStack++;
            });
        }
        return this.doms;
    }
    public add(key:string, dom:MutableRefObject<any>):DOM_DATA{
        this.index++;
        const row:DOM_DATA = {idx:key, isShow:false, index:this.index, zIndex:this.indexStack, dom:dom};
        this.doms.push(row);
        this.indexStack++;
        this.zIndexStack++;
        return row;
    }

    public get(key:string):DOM_DATA{
        let row:DOM_DATA[] = [];
        row = this.doms.filter((d, idx)=> d.idx===key);
        return row[0];
    }

    public abstract select(newIndex:any):void;
    public abstract show(newIndex:any, wrap?:MutableRefObject<any>):void;
    public abstract hide(newIndex:any, wrap?:MutableRefObject<any>):void;
}