import React from 'react';
import AbstractReactComponent from "../core/view/AbstractReactComponent";
import {DEFAULT_LAYOUT} from "../core/layout/BILayout";
import ILSSLayout from "./layout/ILSSLayout";

interface POPUP_STATE{
    [key:string]:number
}
export default class Intro extends AbstractReactComponent<any> {

    render(): JSX.Element {
        return (
            <ILSSLayout type={DEFAULT_LAYOUT.NO_FRAME}>
                로딩
            </ILSSLayout>
        );
    }
}
