import AbstractReactComponent from "../view/AbstractReactComponent";
import {DEFAULT_CONFIG} from "../config/DefaultConfig";
import axios from "axios";

export interface FETCH_EVENT {
    success?:Function,
    error?:Function
}

export type OBJECT_DEFAULT = {
    [key:string]:any;
}

export interface TRANS_DATA {
    header:OBJECT_DEFAULT,
    body:{
        request:OBJECT_DEFAULT,
        response:OBJECT_DEFAULT
    }
}
export default class Transaction {
    component:AbstractReactComponent<any>|undefined;
    token?:string;
    static with(comp?: AbstractReactComponent<any>) {
        const t:Transaction = new Transaction();
        t.component = comp;
        return t;
    }
    setToken(token?:string) {
        this.token = token;
    }

    getParameter(param?:any):OBJECT_DEFAULT {
        let p:OBJECT_DEFAULT = {
            header:{},
            body:{
                request:{},
                response:{}
            }
        };
        if(param) p.body.request = param;
        return p;
    }

    setDefaultCallback(callback?:FETCH_EVENT):FETCH_EVENT {
        if(!callback) callback = {};
        if(!callback.success) callback.success = (data:any) => {

        }
        if(!callback.error) callback.error = (error:any) => {

        }
        return callback;
    }

    post(service:string, param?:any, callback?:FETCH_EVENT){
        const axiosInstance = axios.create({
            baseURL: DEFAULT_CONFIG.domain+'/api/service/v1',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'AuthorizationToken': this.token,
            }
        });
        const p = this.getParameter(param);
        console.log(p, service);
        axiosInstance.post(service, JSON.stringify(p))
        .then(function (response) {
            let body:any = {};
            if(response && response.data && response.data.body){
                body = response.data.body;
                if(body.response){
                    if(callback && callback.success) {
                        callback.success(body.response, response.data);
                    }
                }
            }
        })
        .catch(function (error) {
            console.log(error);
            let errorRes = {
                errCode: "99999",
                errHelpMessage: "서버로부터 응답이 올바르지 않습니다."
            }
            let body:any = {};
            if(error.response && error.response.data && error.response.data.body){
                body = error.response.data.body;
                if(body.response) errorRes = body.response;
            }
            let ret = true;
            if(callback && callback.error) {
                ret = callback.error(errorRes, body, error);
            }
            alert(`[${errorRes.errCode}] ${errorRes.errHelpMessage}`);
        });
    }

    url(url:string, param?:any, callback?:FETCH_EVENT){
        const axiosInstance = axios.create({
            baseURL: DEFAULT_CONFIG.domain,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'AuthorizationToken': this.token,
            }
        });
        const p = this.getParameter(param);
        let service = url;
        axiosInstance.post(service, JSON.stringify(p))
        .then(function (response) {
            let body:any = {};
            if(response && response.data && response.data.body){
                body = response.data.body;
                if(body.response != null){
                    if(callback && callback.success) {
                        callback.success(body.response, response.data);
                    }
                }
            }
        })
        .catch(function (error) {
            console.log(error);
            let errorRes = {
                errCode: "99999",
                errHelpMessage: "서버로부터 응답이 올바르지 않습니다."
            }
            let body:any = {};
            if(error.response && error.response.data && error.response.data.body){
                body = error.response.data.body;
                if(body.response != null) errorRes = body.response;
            }
            let ret = true;
            if(callback && callback.error) {
                ret = callback.error(errorRes, body, error);
            }
            alert(`[${errorRes.errCode}] ${errorRes.errHelpMessage}`);
        });
    }
}