import React from "react";
import Intro from "./business/Intro";
import ILSSBoard from "./business/ILSSBoard";
import AuthSuccess from "./business/AuthSuccess";
import GptTest from "./business/GptTest";

export let route = [
    {title:"월드", path: "/",  element: <ILSSBoard/>, show:false},
    {title:"증인완료", path: "/google/success",  element: <AuthSuccess/>, show:false},
    {title:"인트로", path: "/intro",  element: <Intro/>, show:false},
    {title:"gpt", path: "/gpt",  element: <GptTest/>, show:false},
    // {
    //     id:"AML", title:"AML",
    //     children:[
    //         {title:"AML DDL 분석기", path: "/AML0100", element: <AML0100/>},
    //         {title:"AML DML 파서", path: "/AML0200", element: <AML0200/>},
    //     ]
    // },
]

let index:number = 0;
const indexing = (node:any, parent:any)=>{
    node.forEach((n:any, i:number)=>{
        n.parent = parent;
        if(n.id === undefined) n.id = String(index++);
        if(n.show !== false) n.show = true;
        if(n.children && n.children.length > 0) indexing(n.children, n);
    });
};
indexing(route, undefined);


export namespace MenuRouter{
    export const welcome = "/";
    export const FindMenuById = (menus:any, id:string):any=>{
        if(menus){
            const selected = menus.filter((m:any)=>m.id === id);
            if(selected && selected.length > 0){
                return selected[0];
            }
        }
        return undefined;
    }
    export const FindCurrentRoute = (menus:any, path:string):any=>{
        if(path && menus){
            if(path.indexOf("?") > -1) path = path.substring(0, path.indexOf("?"));
            if(path == "" || path == "/") path = welcome;
            const selected = menus.filter((m:any)=>m.path === path);
            if(selected && selected.length > 0){
                let pick = selected[0];
                if(pick.children && pick.children.length > 0){
                    pick = FindCurrentRoute(pick.children, path);
                    if(pick !== null) {
                        return pick;
                    }
                }
                return pick;
            }else{
                let pick:any = undefined;
                menus.forEach((m:any)=>{
                   if(m.children && m.children.length > 0 && pick === undefined){
                       pick = FindCurrentRoute(m.children, path);
                   }
                });
                return pick;
            }
        }
        return undefined;
    }
    export const Root = (menu:any):any=>{
        if(menu){
            if(menu.parent){
                return Root(menu.parent);
            }else{
                return menu;
            }
        }
        return undefined;
    }
    export const Navi = (menu:any, navi:any):any=>{
        if(!navi) navi = [];
        if(menu){
            navi.push(menu);
            if(menu.parent){
                return Navi(menu.parent, navi);
            }
        }
        return navi;
    }
}
