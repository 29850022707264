import {DOM_DATA} from "../animation/DomAnimator";
import {REF_OBJECTS} from "../../core/define/types";
import {TabAnimator} from "../animation/TabAnimator";
import {PopupAnimator} from "../animation/PopupAnimator";

enum UX_TAB_MODE {
    IS_TAB, IS_POPUP
}

interface TAB_COMP_STATUS {
    idx: number,
    popupKey: string,
    mode: UX_TAB_MODE
}

export interface UX_STATUS{
    currTab:number,
    tabModes:TAB_COMP_STATUS[],
}

export default class UXStatus {
    uxStatus: UX_STATUS = {
        currTab:2,
        tabModes: [
            {idx: 0, mode: UX_TAB_MODE.IS_TAB, popupKey:'worldchat'},
            {idx: 1, mode: UX_TAB_MODE.IS_TAB, popupKey:'communitychat'},
            {idx: 2, mode: UX_TAB_MODE.IS_TAB, popupKey:'worldchat'},
            {idx: 3, mode: UX_TAB_MODE.IS_TAB, popupKey:'worldchat'},
            {idx: 4, mode: UX_TAB_MODE.IS_TAB, popupKey:'worldchat'},
        ]
    };
    zIndex:number = 1000;
    tabAnimation:TabAnimator = new TabAnimator();
    popupAnimation:PopupAnimator = new PopupAnimator();

    constructor() {
        this.popupAnimation.init(10);
    }
    public setTab(idx:number){
        if (idx > 4) idx = 4;
        if (idx < 0) idx = 0;

        const mode:TAB_COMP_STATUS = this.uxStatus.tabModes[idx];
        if(mode.mode === UX_TAB_MODE.IS_POPUP) {
            this.showPopupWithTab(mode.popupKey, false);
        }else{
            if(this.uxStatus.currTab !== idx){
                this.tabAnimation.select(idx);
            }else{
                mode.mode = UX_TAB_MODE.IS_POPUP;
                this.showPopupWithTab(mode.popupKey, true);
            }
        }
        this.uxStatus.currTab = idx;
    }

    public setTabElement(elements:REF_OBJECTS){
        this.tabAnimation.clear();
        if (elements) {
            Object.keys(elements).forEach(key => {
                this.tabAnimation.add(key, elements[key]);
            });
        }
    }

    public getTabTitle():string{
        if(this.uxStatus.currTab == 0) {    //마이페이지
            return "내정보";
        }else if(this.uxStatus.currTab == 1) {    //커뮤니티
            return "커뮤니티";
        }else if(this.uxStatus.currTab == 2) {    //추종자리스트
            return "추종목록";
        }else if(this.uxStatus.currTab == 3) {    //상점
            return "상점";
        }else if(this.uxStatus.currTab == 4) {    //설정
            return "설정";
        }
        return "";
    }

    public addPopupElement(elements:REF_OBJECTS){
        this.popupAnimation.clear();
        if (elements) {
            Object.keys(elements).forEach(key => {
                this.popupAnimation.add(key, elements[key]);
            });
        }
    }

    public flipPopup(id:string){
        const p:DOM_DATA = this.popupAnimation.get(id);
        if(p.isShow) this.popupAnimation.hide(id);
        else this.popupAnimation.show(id);
    }

    public getTabModeFromPopupId(id:string):TAB_COMP_STATUS[]{
        let mode:TAB_COMP_STATUS[] = [];
        this.uxStatus.tabModes.forEach((m:TAB_COMP_STATUS)=>{
            if(m.popupKey === id) mode.push(m);
        });
        return mode;
    }

    public showPopupWithTab(id:string, show:boolean){
        const mode = this.getTabModeFromPopupId(id);
        if(mode) {
            mode.forEach((m:TAB_COMP_STATUS)=>{
                m.mode = show ? UX_TAB_MODE.IS_POPUP : UX_TAB_MODE.IS_TAB;
            });
        }
        if(show) this.popupAnimation.show(id);
        else this.popupAnimation.hide(id);
    }

    public showPopup(id:string, show:boolean){
        if(show) this.popupAnimation.show(id);
        else this.popupAnimation.hide(id);
    }

    public hideAllPopup(){
        this.popupAnimation.hideAll();
        this.uxStatus.tabModes.forEach((mode:TAB_COMP_STATUS)=>{
            mode.mode = UX_TAB_MODE.IS_TAB;
        });
    }
}

export let uxStatus = new UXStatus();