import React from 'react';
import {MenuRouter, route} from "../../ContentRoute";
import AbstractReactComponent from "../../core/view/AbstractReactComponent";

export default class ILSSCHeader extends AbstractReactComponent<any> {

    state:{title:string} = {title:''};

    getChildMenuJsx(menu:any): JSX.Element {
        let jsx: JSX.Element[] = [];
        if (menu && menu.children && menu.children.length > 0) {
            for (let i = 0; i < menu.children.length; i++) {
                const child = menu.children[i];
                jsx.push(<li key={child.id} className={child.id == menu.id ? "active" : ""}>{child.title}</li>);
            }
        }
        return <ul>{jsx}</ul>
    }
    getDepthMenu(depth:number): JSX.Element {
        let jsx: JSX.Element[] = [];
        const select = MenuRouter.FindCurrentRoute(route, window.location.pathname);
        const root = MenuRouter.Root(select);
        if (depth == 0) {   //최상위 대메뉴
            route.forEach((menu:any) => {   //1단계 메뉴
                if (menu.show) {
                    jsx.push(<li key={menu.id} className={menu.id == root.id ? "current on" : ""}>
                        <a><span>{menu.title}</span></a>
                    </li>);
                }
            });
        }else if (depth == 1) { //2단계 메뉴
            if(select && select.parent){
                select.parent.children.forEach((menu:any) => {
                    jsx.push(<li key={menu.id} className={menu.id == select.id ? "current on" : ""}>
                        <a><span>{menu.title}</span></a>
                        {this.getChildMenuJsx(menu)}
                    </li>);
                });
            }else {
                return <></>;
            }
        }else if (depth == -1) {
            const navi = MenuRouter.Navi(select, []);
            if (navi) {
                navi.reverse().forEach((m:any, idx:number) => {
                    jsx.push(<span key={idx} >{select.title}</span>);
                });
            }
            return <>{jsx}</>;
        }
        return <>{jsx}</>;
    }

    render(): JSX.Element {
        let layout: JSX.Element = <></>;
        layout = (<>
            <div className={"content-head"}>
                <h2 className="h_title">{this.state.title}</h2>
            </div>
        </>);
        return layout;
    }
}
