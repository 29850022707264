import React from 'react';
import AbstractReactComponent from "../../core/view/AbstractReactComponent";
import ILSSHeader from "./ILSSHeader";
import ILSSCHeader from "./ILSSCHeader";

export enum DEFAULT_LAYOUT{
    MAIN, MNU, CH, CH_B, B, NO_FRAME, POPUP
}
interface COMP_PROP{
    type:DEFAULT_LAYOUT
}
export default class ILSSLayout extends AbstractReactComponent<any> {

    state:COMP_PROP = {type:DEFAULT_LAYOUT.MNU};
    container:React.RefObject<HTMLDivElement> = React.createRef();
    bg:React.RefObject<HTMLDivElement> = React.createRef();

    static getDerivedStateFromProps(nextProps: COMP_PROP, prevState: COMP_PROP) {
        let p: COMP_PROP = {type:DEFAULT_LAYOUT.MNU};
        AbstractReactComponent.pullups(prevState, nextProps, p, "type");
        return p;
    }

    componentDidMount() {
        super.componentDidMount();
        setTimeout(()=>{
            if(this.container.current){
                this.container.current.style.opacity = `1`;
            }
        }, 500);

    }

    render(): JSX.Element {
        let layout:JSX.Element = <></>;
        if(this.state.type == DEFAULT_LAYOUT.MAIN){
            layout =
                layout = <>
                    <div className={"page-container"}>
                        <header className="page-header"><ILSSHeader context={this}></ILSSHeader></header>
                        <div className={"page-body"}>
                            <section className={"page-content"} id={"content"}>
                                <div className={"content-body"} >
                                    <div className={"container"} ref={this.container}>
                                        {this.props.children}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </>
        }else if(this.state.type == DEFAULT_LAYOUT.MNU){
            layout = <>
                <div className={"page-container"}>
                    <header className="page-header"><ILSSHeader context={this}></ILSSHeader></header>
                    <div className={"page-body"}>
                        <section className={"page-content"} id={"content"}>
                            <div className="content-body" >
                                <ILSSCHeader context={this}></ILSSCHeader>
                                <div className="container" ref={this.container}>
                                    {this.props.children}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </>
        }else if(this.state.type == DEFAULT_LAYOUT.NO_FRAME){
            layout = <>
                <div className={"page-body no-frame"} ref={this.container}>
                    {this.props.children}
                </div>
            </>
        }else if(this.state.type == DEFAULT_LAYOUT.POPUP){
            layout = <>
                <div className={"popup-container"} ref={this.container}>
                    <div className='popup-bg' ref={this.bg}></div>
                    <div className={'popup-content'}>
                        {this.props.children}
                    </div>
                </div>
            </>
        }
        return layout;
    }
}
