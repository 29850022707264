import {MutableRefObject} from "react";
import {DomAnimator} from "./DomAnimator";

export class TabAnimator extends DomAnimator{

    public select(newIndex:any){
        if(!this.doms) return;
        this.doms.forEach((d, idx)=> {
            const transX = (idx-newIndex)*100;
            const scaleX = Math.abs(idx-newIndex)*2;
            if(d.dom && d.dom.current) d.dom.current.style.transform = `translateX(${transX}%) scale(${1-scaleX/10})`;
        });
        this.index = newIndex;
    }

    public show(newIndex:any, wrap?:MutableRefObject<any>){
        this.doms.forEach((d, idx)=> {
            if(d.idx===newIndex) {
                const transX = -100;
                // d.dom.current.style.display = "block";
                d.dom.current.style.transform = `translateY(${transX}%)`;
                if(wrap) wrap.current.style.transform = `translateY(${transX}%)`;
            }
        });
        this.index = newIndex;
    }
    public hide(newIndex:any, wrap?:MutableRefObject<any>){
        if(newIndex == -1) {
            this.doms.forEach((d, idx)=> {
                // d.dom.current.style.display = "none";
                d.dom.current.style.transform = `translateY(0%)`;
            });
            if(wrap) wrap.current.style.transform = `translateY(0%)`;
        }else{
            this.doms.forEach((d, idx)=> {
                if(d.idx===newIndex) {
                    // d.dom.current.style.display = "none";
                    d.dom.current.style.transform = `translateY(0%)`;
                    if(wrap) wrap.current.style.transform = `translateY(0%)`;
                    return;
                }
            });
        }
        this.index = newIndex;
    }

    public hideWrap(wrap?:MutableRefObject<any>){
        if(wrap) wrap.current.style.transform = `translateY(0%)`;
    }
}