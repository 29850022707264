import React from 'react';
import AbstractReactComponent from "../view/AbstractReactComponent";

export default class Left extends AbstractReactComponent<any> {

    render(): JSX.Element {
        let layout: JSX.Element = <></>;
        layout = (<>
            <div className="left-menu"></div>
        </>);
        return layout;
    }
}
