import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import {route} from "./ContentRoute";


const router = createBrowserRouter(route);

function App() {
  return (
      <RouterProvider router={router} />
  );
}

export default App;
