import {EVENT, REF_OBJECTS} from "../define/types";
import React from "react";
import Transaction, {FETCH_EVENT} from "../transaction/Transaction";
import {createBrowserHistory} from "history";
import Cookies from 'js-cookie';

export enum TICKER_EVENT {
    START, TICK, STOP,  END
}
export default abstract class AbstractReactComponent<T extends AbstractReactComponent<any>> extends React.Component<any, any, any>{
    contextMain: T = this.props.context;
    contextChild:AbstractReactComponent<any>[] = [];
    transaction:Transaction = Transaction.with(this);
    props:any = {};
    $pathParams:any = {};

    lazyLoader:Function[] = [];

    constructor(props:any) {
        super(props);
        this.props = props;
        this.contextMain?.contextChild.push(this);
        const searchParams = new URLSearchParams(document.location.search);
        this.$pathParams = {};
        for (const [key, value] of searchParams.entries()) {
            this.$pathParams[key] = value;
        }
        if(!window.ContextScope) window.ContextScope = {};
        window.ContextScope[this.constructor.name] = this;
    }

    lazy(work: Function) {
        this.lazyLoader.push(work);
    }

    getResponse(service:string, param?:any, callback?:FETCH_EVENT){
        callback = this.transaction.setDefaultCallback(callback);
        const errorCallback = callback.error;
        callback.error = ()=>{
            // alert(JSON.stringify(arguments));
            // if(errorCallback) errorCallback(res, data);
        }
        this.transaction.post(service, param, callback);
    }
    getResponse2(service:string, param?:any, callback?:FETCH_EVENT){
        setTimeout(()=>{
           if(callback && callback.success) {
               callback.success();
           }
        });
    }
    getRootContext<K extends AbstractReactComponent<any>>():K|any{
        if(!this.contextMain || !this.contextMain.getRootContext()){
            return this;
        }
        return this.contextMain.getRootContext();
    }

    broadcast(event:EVENT, data:any, spread?:boolean){
        this.contextChild.forEach((c)=>{
            c.on(event, data, spread);
        });
    }
    emit(event:EVENT, data:any){
        try{
            this.contextMain?.on(event, data, false);
        }catch(e){}
    }
    callback(event:EVENT, data:any, caller:AbstractReactComponent<T>){}
    on(event:EVENT, data:any, spread?:boolean){
        if(!spread) spread = true;
        if(spread) this.broadcast(event, data);
    }
    componentDidMount() {
        this.lazyLoader.forEach((f)=>{
            f();
        });
    }
    componentWillUnmount() {}

    apply(callback:Function, args:any[]){
        try{
            if (callback && this.contextMain) {
                callback.apply(this.contextMain, args);
            }
        }catch(e){
            console.log(e);
        }
    }

    move(path:string){
        const history = createBrowserHistory();
        history.push(path);
        window.location.href = path;
    }

    toRef(key: string[]):REF_OBJECTS {
        const elements:REF_OBJECTS = {};
        key.forEach((k)=>{
            elements[k] = React.createRef();
        });
        return elements;
    }

    /**
     * prop > target 에 안전하게 넣는다
     * @param prop
     * @param target
     * @param keys
     */
    public static pullups(prop: any, state: any, target: any,  ...keys: string[]) {
        this.pullup(prop, target, ...keys);
        this.pullup(state, target, ...keys);
    }
    public static pullup(prop: any, target: any, ...keys: string[]) {
        if(target && prop){
            keys.forEach(key=>{
                try{
                    const val:any = eval(`prop.${key}`);
                    if(key.indexOf(".") > -1){
                        const propKeys = key.split(".");
                        let currentObj = target;
                        let lastKey = propKeys[0];
                        for (let i = 0; i < propKeys.length; i++) {
                            const prop = propKeys[i];
                            if (!currentObj[prop]) {
                                currentObj[prop] = {};
                            }
                            currentObj = currentObj[prop];
                            lastKey = prop;
                        }
                        if(val !== undefined && val != null) eval(`target.${lastKey} = val`);
                    }else{
                        if(val !== undefined && val != null) eval(`target.${key} = val`);
                    }
                }catch(e){
                    // console.log(e);
                }
            });
        }
    }

    public toRate(val1: any,maxVal: any, digit:number):string {
        let value1:number = this.null2int({val:val1}, 'val', 0);
        let value2:number = this.null2int({val:maxVal}, 'val', 0);
        let ret:string = "0"
        try {
            if (value1 !== 0 && value2 !== 0){
                let val:string = ((value1/value2)*100).toFixed(digit).toString();
                ret = val;
                if (digit > 0) {
                    let fVal:string = val.substring(0, val.indexOf("."));
                    let rVal:string = val.substring(val.indexOf("."));
                    fVal = this.toComma({val:val1}, 'val');
                    ret = fVal+rVal;
                }
            }

        }catch(e){
            console.log(e);
        }

        return ret;
    }

    public toCommaN(obj: any, defaultValue?:number):string {
        let value:number = defaultValue?defaultValue:0;
        try{
            value = parseInt(obj);
        }catch(e){
            console.log(e);
        }
        let ret:string = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return ret;
    }
    public toPlusNumber(obj: any):string {
        let value:any = obj;
        try{
            if(value == undefined) value = 0;
            if(value > 0) value = "+"+value;
        }catch(e){
            console.log(e);
        }
        return value;
    }

    public toComma(obj: any, key: string, defaultValue?:number):string {
        let value:number = this.null2int(obj, key, defaultValue);
        return this.toCommaN(value, defaultValue);
    }

    public null2str(obj: any, key: string, defaultValue?:string):string {
        if (obj != null && obj[key] != null) {
            return String(obj[key]);
        }
        return defaultValue||"";
    }

    public null2int(obj: any, key: string, defaultValue?:number):number {
        let ret:number = 0;
        if (obj != null && obj[key] != null) {
            try{ret = parseInt(obj[key]);}catch(e){}
            return ret;
        }
        return defaultValue||0;
    }

    formatRemainingTime(timestamp: number): string {
        const diff = timestamp;

        if (diff < 0) {
            return "완료";
        }

        const SECOND = 1000;
        const MINUTE = 60 * SECOND;
        const HOUR = 60 * MINUTE;
        const DAY = 24 * HOUR;

        const days = Math.floor(diff / DAY);
        const hours = Math.floor((diff % DAY) / HOUR);
        const minutes = Math.floor((diff % HOUR) / MINUTE);
        // const seconds = Math.floor((diff % MINUTE) / SECOND);

        let ret:string = "";
        if (days > 0) {
            ret += `${days}일`;
        }
        if (hours > 0) {
            ret +=  `${hours}시간`;
        }
        if (minutes > 0) {
            ret +=  `${minutes}분`;
        }
        // if (seconds > 0) {
        //     ret +=  `${seconds}초`;
        // }
        return ret;
    }

    static getCookie(key: string): string|undefined|null {
        return Cookies.get(key);
    }
    static setCookie(key: string, value:string|undefined|null, expires?:number) {
        if(expires === undefined) expires = 9999;
        if(value === undefined || value === null) {
            Cookies.remove(key);
        }else{
            Cookies.set(key, value, {expires: expires});
        }
    }

    static getOCookie(key: string): any {
        let val = this.getCookie(key);
        try{
            if (val) return JSON.parse(decodeURI(val));
        }catch(e){
            console.log('Object cookie read error', e);
        }
        return undefined;
    }
    static setOCookie(key: string, value:any, expires?:number) {
        try{
            this.setCookie(key, encodeURI(JSON.stringify(value)), expires);
        }catch(e){
            console.log('Object cookie write error', e);
        }
    }

    public abstract render():JSX.Element;
}