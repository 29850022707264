/*============= 사용자 정보 ===============*/
export const GOOGLE_AUTH_SCOPE = [
    'https://www.googleapis.com/auth/contacts.readonly',
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/user.birthday.read',
];
export interface PLAYER_INFO{    //사용자 정보
    id:string
    chn:string
    name:string
    access_token:string
    nick_name:string
    auth_yn:string
    auth_type:string
    ntl_cd:string
    ntl_no:string
    email:string
    usr_status:string
    block_desc:string
    last_login_dt:string
}
/*============= //사용자 정보 ===============*/

/*============= 게시판정보 ===============*/

export enum CHAT_TYPE{
    TEXT, //일반
}
export enum BOARD_TYPE{
    PREBOARD,
    NOTICE,
    QNA,
    FAQ,
    ETC,
}
export interface BOARD_PAGE{
    page:number,
    block?:number,
    board_content?:BOARD_CONTENT[],
}
export interface BOARD_CONTENT{
    seq?:number,
    p_seq?:number,
    bcont_cd?:string,
    usr_seq?:number,
    id?:string,
    email:string,
    email1?:string,
    email2?:string,
    nick_name?:string,
    name?:string,
    cmm_type?:string,
    title?:string,
    cont:string,
    read_cnt?:number,
    reg_dtm?:string,
}
/*============= 게시판정보 ===============*/