import Cookies from "js-cookie";

class CookieUtil {

    static getCookie(key: string): string | undefined | null {
        return Cookies.get(key);
    }

    static setCookie(key: string, value: string | undefined | null, expires?: number) {
        if (expires === undefined) expires = 9999;
        if (value === undefined || value === null) {
            Cookies.remove(key);
        } else {
            Cookies.set(key, value, { expires: expires });
        }
    }

    static getOCookie(key: string): any {
        let val = this.getCookie(key);
        try {
            if (val) return JSON.parse(decodeURI(val));
        } catch (e) {
            console.log('Object cookie read error', e);
        }
        return undefined;
    }

    static setOCookie(key: string, value: any, expires?: number) {
        try {
            this.setCookie(key, encodeURI(JSON.stringify(value)), expires);
        } catch (e) {
            console.log('Object cookie write error', e);
        }
    }

    static getLocalStorage(key: string): any {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : null;
        } catch (e) {
            console.log('Local Storage read error', e);
            return null;
        }
    }

    static setLocalStorage(key: string, value: any) {
        try {
            const item = JSON.stringify(value);
            window.localStorage.setItem(key, item);
        } catch (e) {
            console.log('Local Storage write error', e);
        }
    }
}

export default CookieUtil;
window.CookieUtil = CookieUtil;