import React from 'react';

import ILSSBoard from "../ILSSBoard";
import AbstractReactComponent from "../../core/view/AbstractReactComponent";
import {EVENT, REF_OBJECTS} from "../../core/define/types";
import {BOARD_CONTENT} from "../define/types";
import {frontUtil} from "../../core/util/FrontUtil";
import {validator} from "../../core/util/Validate";
import RCombo, {COMBO_DATA} from "./RCombo";
import {googleCertifier, GoogleCredential} from "../auth/GoogleCertifier";
import {nerdfoxCertifier} from "../auth/NerdFoxCertifier";

interface COMM_PROPS {
    sender:string, board_content?:BOARD_CONTENT, credentials?: GoogleCredential
}

/**
 * 커뮤니티 채팅방
 */
export default class BoardWrite extends AbstractReactComponent<ILSSBoard> {
    //상태값을 저장
    state:COMM_PROPS = {sender:'boardwrite'};
    els:REF_OBJECTS = {};

    comboData1: COMBO_DATA[] = [
        {value: 'naver.com', label: 'naver.com'},
        {value: 'gmail.com', label: 'gmail.com'},
        {value: 'daum.net', label: 'daum.net'},
        {value: 'hanmail.net', label: 'hanmail.net'}, // 다음의 한메일 서비스
        {value: 'nate.com', label: 'nate.com'}, // 네이트 메일
        {value: 'korea.com', label: 'korea.com'}, // 코리아닷컴 메일
        {value: 'yahoo.co.kr', label: 'yahoo.co.kr'}, // 야후 코리아
        {value: 'chol.com', label: 'chol.com'}, // 천리안 메일
        {value: 'empal.com', label: 'empal.com'}, // 엠팔 메일
        {value: 'hanmir.com', label: 'hanmir.com'}, // 한미르 메일
        {value: 'hotmail.com', label: 'hotmail.com'}, // 핫메일
        {value: 'outlook.com', label: 'outlook.com'}, // 아웃룩 메일
        {value: 'kakao.com', label: 'kakao.com'} // kakao 메일
    ];

    constructor(props?: any) {
        super(props);
        this.els = this.toRef(['email', 'cont', 'email1', 'email2']);
        // if (!googleCertifier.credential) {
            googleCertifier.signJwtIn((e:EVENT, res:GoogleCredential)=>{
                console.log(res)
                this.setState({credentials:res});
                this.getRootContext().on(EVENT.ON, {sender:'context', type:"credentials", data:res});
                // nerdfoxCertifier.setToken(res.credential.accessToken);
                nerdfoxCertifier.setToken(res.credential.idToken);
                nerdfoxCertifier.signJwtIn((e:EVENT, res:any)=>{
                    console.log(e, res)
                })
            });
        // }
    }

    static getDerivedStateFromProps(nextProps: COMM_PROPS, prevState: COMM_PROPS) {
        let p: COMM_PROPS = {sender:'boardwrite', board_content:{email:'', cont:'', email1:'', email2:''}};
        AbstractReactComponent.pullups(prevState, nextProps, p, 'sender', 'board_content', 'credentials');
        return p;
    }

    on(event: EVENT, data: any, spread?: boolean) {
        super.on(event, data, spread);
        if(event === EVENT.ON){
            if(data.type === 'credentials'){
                this.setState({credentials:data.data});
            }else if(data.type === 'open'){
                let board_content = this.state.board_content;
                let credentials = this.state.credentials;
                if(board_content === undefined) board_content = {email:'', cont:'', email1:'', email2:''};
                if (credentials && credentials.user) {
                    if(credentials.user?.email) {
                        board_content.email = credentials.user?.email;
                        board_content.email1 = frontUtil.parseEmail(board_content.email)[0];
                        board_content.email2 = frontUtil.parseEmail(board_content.email)[1];
                    }
                }
                this.setState({board_content:board_content});
            }
        }
    }

    btnClick(type:string):void{
        if(type === 'close'){
            this.getRootContext().on(EVENT.ON, {sender:this.state.sender, type:"close"});
        }else if(type === 'save' && this.state.board_content){
            if(!this.state.board_content.email){
                alert('이메일을 입력해주세요.');
                this.els.email.current?.focus();
                return;
            }
            if(!this.state.board_content.cont){
                alert('내용을 입력해주세요.');
                this.els.cont.current?.focus();
                return;
            }
            if(!validator.email(this.state.board_content.email)){
                return;
            }
            this.getResponse('/preboard.insertboard', this.state.board_content, {
                success:(res:any, data:any)=>{
                    this.getRootContext().on(EVENT.ON, {sender:this.state.sender, type:"close", refresh:true});
                    this.setState({board_content:{email:'', cont:''}})
                }
            });
        }
    }

    bindFrom(type:string, val:any, e:any){
        if(e && e.target){
            let board_content :BOARD_CONTENT|undefined = this.state.board_content;
            if(!board_content) board_content = {email:'', cont:'', email1:'', email2:''};
            if(type === 'email1') {
                board_content.email1 = e.target.value;
            }else if(type === 'email2') {
                board_content.email2 = e.target.value;
            }else if(type === 'email') {
                board_content.email1 = frontUtil.parseEmail(board_content.email)[0];
                board_content.email2 = frontUtil.parseEmail(board_content.email)[1];
            }

            board_content.email = `${board_content.email1}@${board_content.email2}`
            if(type === 'cont') board_content.cont = e.target.value;
            board_content.nick_name = board_content.email;
            board_content.title = board_content.nick_name+'님의 한마디';
            this.setState({ board_content: board_content });
        }
    }

    render(): JSX.Element {
        return (
            <>
                <div className="board-content">
                    <h2 className={'title'}>나도 한마디 해야겠어!</h2>
                    <div className={'wrap'}>
                        <div className={'email'}>
                            <input ref={this.els.email1} type="email" placeholder="이메일 입력" required value={this.state.board_content?.email1} onChange={(e)=>this.bindFrom('email1', e.target.value, e)}/>
                            <strong>@</strong>
                            <RCombo ref={this.els.email2} context={this} val={this.state.board_content?.email2} options={this.comboData1} onChange={(val:any, e:any)=>this.bindFrom('email2', val, e)}></RCombo>
                        </div>
                        <div className={'cont'}>
                            <textarea ref={this.els.cont} className={'write-cont'} placeholder="남기실 말이 무엇인가요?" required value={this.state.board_content?.cont} onChange={(e)=>this.bindFrom('cont', e.target.value, e)}></textarea>
                        </div>
                        <div className={'btn-set dual'}>
                            <button className={'btn btn-red btn-ico4 scaled-el'} onClick={()=>this.btnClick('close')}>닫기</button>
                            <button className={'btn btn-green btn-ico3 scaled-el'} onClick={()=>this.btnClick('save')}>저장</button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

