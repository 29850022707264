import React from 'react';

import ILSSBoard from "../ILSSBoard";
import AbstractReactComponent from "../../core/view/AbstractReactComponent";
import {EVENT} from "../../core/define/types";
import {GoogleCredential} from "../auth/GoogleCertifier";

interface COMM_PROPS {
    sender:string, credentials?: GoogleCredential
}

/**
 * 유저정보 요약
 */
export default class UserInfo extends AbstractReactComponent<ILSSBoard> {
    //상태값을 저장
    state:COMM_PROPS = {sender:'userinfo'};

    static getDerivedStateFromProps(nextProps: COMM_PROPS, prevState: COMM_PROPS) {
        let p: COMM_PROPS = {sender:'userinfo'};
        AbstractReactComponent.pullups(prevState, nextProps, p, 'credentials');
        return p;
    }

    btnClick(type:string):void{
        if(type === 'close'){
            this.getRootContext().on(EVENT.ON, {sender:this.state.sender, type:"close"});
        }
    }

    render(): JSX.Element {
        let userJson:string = '';
        if (this.state.credentials) {
            userJson = JSON.stringify(this.state.credentials.user.providerData, null, 2);
        }
        return (
            <>
                <div className="about-content">
                    <h2 className={'title'}>{this.state.credentials?.user.displayName}({this.state.credentials?.user.email})</h2>
                    <div className={'wrap'}>
                        {userJson}
                    </div>
                </div>
            </>
        );
    }
}

