import dayjs from 'dayjs';
import {v4} from "uuid";
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ko'; // 한국어 로케일을 사용하기 위해

dayjs.extend(relativeTime); // relativeTime 플러그인 활성화
dayjs.locale('ko'); // 한국어 로케일 설정

export default class FrontUtil{

    public uuid(){
        return v4();
    }
    public toRate(val1: any,maxVal: any, digit:number):string {
        let value1:number = this.null2int({val:val1}, 'val', 0);
        let value2:number = this.null2int({val:maxVal}, 'val', 0);
        let ret:string = "0"
        try {
            if (value1 !== 0 && value2 !== 0){
                let val:string = ((value1/value2)*100).toFixed(digit).toString();
                ret = val;
                if (digit > 0) {
                    let fVal:string = val.substring(0, val.indexOf("."));
                    let rVal:string = val.substring(val.indexOf("."));
                    fVal = this.toComma({val:val1}, 'val');
                    ret = fVal+rVal;
                }
            }

        }catch(e){
            console.log(e);
        }

        return ret;
    }

    public toComma(obj: any, key: string, defaultValue?:number):string {
        let value:number = this.null2int(obj, key, defaultValue);
        let ret:string = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return ret;
    }

    public null2str(obj: any, key: string, defaultValue?:string):string {
        if (obj != null && obj[key] != null) {
            return String(obj[key]);
        }
        return defaultValue||"";
    }

    public null2int(obj: any, key: string, defaultValue?:number):number {
        let ret:number = 0;
        if (obj != null && obj[key] != null) {
            try{ret = parseInt(obj[key]);}catch(e){}
            return ret;
        }
        return defaultValue||0;
    }

    public dateTimeAgo(input?: string): string {
        if (input) {
            const inputDate = dayjs(input, "YYYYMMDD HH:mm:ss");
            return inputDate.fromNow();
        }
        return '';
    }

    public getRandomNumber(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    public lpad(num:number, size:number): string {
        let s = num+"";
        while (s.length < size) s = "0" + s;
        return s;
    }

    public rdLpad(max:number, size:number): string {
        const rn:number = this.getRandomNumber(1, max);
        let s = rn+"";
        while (s.length < size) s = "0" + s;
        return s;
    }

    public emailMask(email:string): string {
        if (email && email.indexOf("@") > -1) {
            const [id, domain] = email.split('@');

            if (!id || !domain) {
                return email; // 유효하지 않은 이메일 형식인 경우, 원본 반환
            }

            const visiblePart = id.length > 3 ? id.substring(0, 3) : id; // ID의 처음 3글자
            const maskedPart = id.length > 3 ? '*'.repeat(id.length - 3) : ''; // 나머지 부분 마스킹

            return `${visiblePart}${maskedPart}@${domain}`;
        }
        return email;
    }

    public parseEmail(email: string | null | undefined): string[] {
        let ret:string[] = ['',''];
        if(email == null || email.length == 0) return ret;
        const mails = email.split('@');
        if(mails[0] && mails[0].length > 0) ret[0] = mails[0];
        if(mails[1] && mails[1].length > 0) ret[1] = mails[1];
        return ret;
    }

}

export const frontUtil:FrontUtil = new FrontUtil();