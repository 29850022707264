import React from 'react';

import ILSSBoard from "../ILSSBoard";
import AbstractReactComponent from "../../core/view/AbstractReactComponent";
import {EVENT} from "../../core/define/types";

interface COMM_PROPS {
    sender:string,
}

/**
 * 커뮤니티 채팅방
 */
export default class AboutUs extends AbstractReactComponent<ILSSBoard> {
    //상태값을 저장
    state:COMM_PROPS = {sender:'aboutus'};

    static getDerivedStateFromProps(nextProps: COMM_PROPS, prevState: COMM_PROPS) {
        let p: COMM_PROPS = {sender:'aboutus'};
        AbstractReactComponent.pullups(prevState, nextProps, p);
        return p;
    }

    btnClick(type:string):void{
        if(type === 'close'){
            this.getRootContext().on(EVENT.ON, {sender:this.state.sender, type:"close"});
        }else if(type === 'quest'){
            this.getRootContext().on(EVENT.ON, {sender:this.state.sender, type:"close"});
            this.getRootContext().onClick('questwrite');
        }
    }

    render(): JSX.Element {
        return (
            <>
                <div className="about-content">
                    <h2 className={'title'}>NERDFOX GAMES</h2>
                    <div className={'wrap'}>
                        <div className={'fox-what'}>
                            <img src={require(`../../../styles/images/gui/NerdFox.png`)} alt="aboutus"/>
                            <div className={'fox-sentense'}>
                                야생의 여우는 재밌는걸 제일 많이 가지고 있어. 나랑 알게된걸 행운으로 여기라구!!.<br/>
                                우리와 함께하면 진심 시간가는 줄 모를걸? 정신을 차리고 보면 이미 네 머리는 희끗희끗 해졌을 거야.
                            </div>
                        </div>
                        <div className={'fox-about'}>
                            <div className={'corp-name'}>(주) 너드폭스 게임즈</div>
                            <div className={'email'}>nerdfox@gmail.com</div>
                            <div className={'copyright'}>Copyright © Nerd Fox .co. All Right Reserved.</div>
                        </div>
                        <div className={'btn-set dual'}>
                            <button className={'btn btn-green btn-ico3 scaled-el'} onClick={()=>this.btnClick('quest')}>문의하기</button>
                            <button className={'btn btn-red btn-ico4 scaled-el'} onClick={()=>this.btnClick('close')}>닫기</button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

