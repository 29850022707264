import Select from "react-select/base";
import AbstractReactComponent from "../../core/view/AbstractReactComponent";
import {BOARD_CONTENT} from "../define/types";

export interface COMBO_DATA {
    value:string,label:string,onChage?:()=>void
}
interface COMM_PROPS {
    options: COMBO_DATA[], selected?: COMBO_DATA, val:string, inputVal:''
}

export default class RCombo extends AbstractReactComponent<any> {
    state:COMM_PROPS = {options:[], val:'naver.com', inputVal:''};

    static getDerivedStateFromProps(nextProps: COMM_PROPS, prevState: COMM_PROPS) {
        let p: COMM_PROPS = {options:[], val:'naver.com', inputVal:''};
        AbstractReactComponent.pullups(prevState, nextProps, p, 'options', 'selected', 'val', 'inputVal');
        return p;
    }

    // 옵션이 변경될 때 호출될 메서드
    handleChange = (e:any) => {
        this.setState({val:e.target.value});
        if(this.props.onChange){
            this.props.onChange(e.target.value, e);
        }
    }

    selectOptions(){
        return this.state.options.map((item, index)=>{
            if(item.value === this.state.val){
                return <option key={index} value={item.value} selected>{item.label}</option>
            }else{
                return <option key={index} value={item.value}>{item.label}</option>
            }
        });
    }


    render() {
        // const opt:any = this.state.options;
        return (
            <select value={this.state.val} onChange={this.handleChange} className={'select-box'}>
                {this.selectOptions()}
            </select>
        )
    }
}
