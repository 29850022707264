import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {FirebaseApp, FirebaseOptions} from "@firebase/app";
import {Analytics} from "@firebase/analytics";

export default abstract class FireBaseCertifier {
    firebaseConfig:FirebaseOptions = {
        apiKey: "AIzaSyBRL_rO-m5h5KcJeMVAwUu21_8Sdp27rmY",
        authDomain: "nerdfox-games.firebaseapp.com",
        projectId: "nerdfox-games",
        storageBucket: "nerdfox-games.appspot.com",
        messagingSenderId: "184701772335",
        appId: "1:184701772335:web:231f3d7259045997d55cda",
        measurementId: "G-FNHCXES0F2"
    };
    app: FirebaseApp;
    analytics: Analytics;

    constructor() {
        this.app = initializeApp(this.firebaseConfig);
        this.analytics = getAnalytics(this.app);
    }
}